import React, { FC } from "react";
import { isMobile } from "react-device-detect";

const PageTitle: FC = ({ children }) => {
  return (
    <h2
      style={
        !isMobile
          ? {
              fontSize: "18px",
              fontWeight: "bold",
              color: "#000",
              marginTop: "110px",
              marginBottom: -20,
            }
          : {
              fontSize: "23px",
              fontWeight: "bold",
              color: "#000",
              marginTop: "110px",
              marginBottom: -20,
              marginLeft: "5px",
            }
      }
    >
      {children}
    </h2>
  );
};

export default PageTitle;
