import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import axios from "../axios";

const VendorTasks = () => {
  const Styles = {
    tittle: {
      color: "#4B566B",
      fontSize: 15,
      fontWeight: "bold",
      paddingLeft: "10px",
      marginTop: "20px",
      display: "flex",
      textAlign: "center",
      marginBottom: "15px",
    },
  };

  const [vendors, setVendors] = useState([]);

  const getVendors = async () => {
    axios.get(`/api/v1/vendorsTop`).then((res) => {
      setVendors(res.data.vendor);
    });
  };

  useEffect(() => {
    getVendors();
  }, []);

  const format = (number) => {
    const splits = number?.split(".")[1];
    const slice = splits?.slice(0, 1) || 0;
    const num = parseInt(number);
    return `${num},${slice}`;
  };
  return (
    <>
      <h2 style={Styles.tittle}> Destacados </h2>

      {vendors &&
        vendors
          .sort((a, b) => (b.ratingPoints || 0) + (a.ratingPoints || 0))
          .map((vendor) => {
            return (
              <>
                <Grid
                  container
                  xs={12}
                  md={12}
                  style={{ marginBottom: "10px" }}
                >
                  <Grid item xs={3} md={3}>
                    <img
                      src={vendor?.image}
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "100",
                      }}
                      alt="storeimage"
                    />
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                      {vendor?.firstName} {vendor?.lastName}
                    </p>
                    <p style={{ fontSize: "12px" }}>{vendor?.storeName}</p>
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <p style={{ fontSize: "12px" }}>
                      {format(`${vendor?.ratingPoints || 0}`)} puntos
                    </p>
                  </Grid>
                </Grid>
              </>
            );
          })}
    </>
  );
};

export default VendorTasks;
