import { Grid, Drawer, Box, CircularProgress } from "@mui/material";
import React, { FC, useState } from "react";
import VendorDashBoard from "../Vendor/VendorDashBoard";
import VendorNavbarComponent from "../Vendor/VendorNavbarComponent";

const drawerWidth = 250;

type Props = {
  tabName: string;
  loading?: boolean;
};

const Layout: FC<Props> = ({ children, tabName, loading }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeTab, setActiveTabs] = useState(tabName);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Grid
      md={3}
      lg={2}
      sm={2}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <VendorDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        background: "#F6F9FC",
        width: "100%",
        height: "100vh",
      }}
    >
      <VendorNavbarComponent handleDrawerToggle={handleDrawerToggle} />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default Layout;
