import React, { useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { isMobile } from "react-device-detect";
import { commafy } from "../components/commafy";
import { formatDate } from "../utils/formatDate";

const VendorTable = ({ order, vendor }) => {
  const navigate = useNavigate();
  const navigateToDetail = (row) => {
    navigate("/OrderDetail", {
      state: {
        ...row,
      },
    });
  };

  function handleBackaground(status) {
    if (status === "Confirmando Pedido") return "#CED7DF";
    if (status === "Pedido Confirmado") return "#dae2e8";
    if (status === "Procesando Pedido") return "#e6e8ea";
    if (status === "Pedido Entregado") return "#E0F7E9";
    if (status === "Cancelada") return "#F9E1E6";
    return status;
  }

  function handleStatus(status) {
    if (status === "Confirmando Pedido") return "Confirmando";
    if (status === "Pedido Confirmado") return "Confirmado";
    if (status === "Procesando Pedido") return "Procesando";
    if (status === "Pedido Entregado") return "Entregado";
    if (status === "Cancelada") return "Cancelado";
    return status;
  }

  const styles = {
    tableHeading: { background: "#529f6f", height: "1.5px", width: "100%" },

    mdTable: {
      background: "white",
      borderSpacing: "45px 10px",
      borderCollapse: "separate",
    },
    mdTableMobile: {
      background: "white",
      borderSpacing: "20px 8px",
      borderCollapse: "separate",
      marginTop: "-65px",
    },

    tableBody: {
      color: "black",
      fontSize: 12,
      textAlign: "center",
      margin: "auto",
    },
    tableTxt: {
      fontSize: 13,
      color: "black",
      padding: "2px",
      margin: "auto",
      textAlign: "center",
    },
    valueTxt: {
      fontSize: 12,
      color: "#222",
    },
    hightLightTxt: {
      fontSize: 12,
      color: "#AD6D49",
      margin: 0,
    },

    tittle: {
      color: "#4B566B",
      fontSize: 15,
      fontWeight: "bold",
      paddingLeft: "10px",
      marginTop: "20px",
    },

    tittleMobile: {
      color: "#4B566B",
      fontSize: 15,
      fontWeight: "bold",
      paddingLeft: "3px",
      marginTop: "10px",
    },

    subTittle: {
      color: "#4B566B",
      fontSize: 13,
      fontWeight: "bold",
      paddingLeft: "10px",
    },

    confirmando: {
      width: 76,
      height: 17,
      borderRadius: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 11,
      padding: 15,
      textAlign: "center",
      fontWeight: "bold",
      color: "black",
    },
  };

  return (
    <>
      <h2 style={{ isMobile } ? styles.tittleMobile : styles.tittle}>
        {" "}
        Ordenes recientes{" "}
      </h2>

      {!isMobile && (
        <MDBTable responsive style={styles.mdTable}>
          <MDBTableHead>
            <tr>
              <th scope="col" style={styles.subTittle}>
                Nro de Orden
              </th>
              <th scope="col" style={styles.subTittle}>
                Estado
              </th>
              <th scope="col" style={styles.subTittle}>
                Fecha
              </th>
              <th scope="col" style={styles.subTittle}>
                Total
              </th>
            </tr>
          </MDBTableHead>

          {order?.slice(0, 5).map((row) => {
            return (
              <>
                <MDBTableBody>
                  <tr
                    style={{
                      marginTop: "10px",
                      marginButton: "10px",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigateToDetail(row);
                    }}
                    hover
                    tabIndex={-1}
                    key={row._id}
                  >
                    <th scope="row" style={styles.tableTxt}>
                      {row._id}
                    </th>

                    <td style={styles.tableTxt}>
                      <div style={styles.confirmando}>
                        <p
                          style={{
                            background: handleBackaground(row.status),
                            borderRadius: "5px",
                            padding: "5px",
                          }}
                        >
                          {handleStatus(row.status)}
                        </p>
                      </div>
                    </td>

                    <td style={styles.tableTxt}>
                      <div>{formatDate(row.purchaseDate, "yyyy-MM-dd")}</div>
                    </td>

                    <td style={styles.tableTxt}>
                      <div>₡{commafy(row.totalPrice, 3)}</div>
                    </td>
                  </tr>
                </MDBTableBody>
              </>
            );
          })}
        </MDBTable>
      )}

      {isMobile && (
        <MDBTable responsive style={styles.mdTableMobile}>
          <MDBTableHead>
            <tr>
              <th scope="col" style={styles.subTittle}>
                Nro de Orden
              </th>
              <th scope="col" style={styles.subTittle}>
                Fecha
              </th>
              <th scope="col" style={styles.subTittle}>
                Total
              </th>
            </tr>
          </MDBTableHead>
          {order?.slice(0, 5).map((row) => {
            return (
              <>
                <MDBTableBody>
                  <tr
                    style={{
                      marginTop: "10px",
                      marginButton: "10px",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigateToDetail(row);
                    }}
                    hover
                    tabIndex={-1}
                    key={row._id}
                  >
                    <th scope="row" style={styles.tableTxt}>
                      {row._id}
                    </th>

                    <td style={styles.tableTxt}>
                      <div>{moment(row.purchaseDate).format("DD/MM/YYYY")}</div>
                    </td>

                    <td style={styles.tableTxt}>
                      <div>₡{commafy(row.totalPrice, 3)}</div>
                    </td>
                  </tr>
                </MDBTableBody>
              </>
            );
          })}
        </MDBTable>
      )}
    </>
  );
};

export default VendorTable;
