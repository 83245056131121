import * as React from "react";
import Box from "@mui/material/Box";
import Colors from "../components/Colors";
import { Grid } from "@mui/material";
import "./Vendor.css";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import IconButton from "@mui/material/IconButton";
export default function VendorNavbarComponent({ handleDrawerToggle }) {
  const navigation = useNavigate();
  const logout = async () => {
    await signOut(auth);
    localStorage.removeItem("vendor");
    navigation("/");
    window.location.reload();
  };
  return (
    <Grid
      container
      columns={{ xs: 11, sm: 12, md: 12 }}
      style={Styles.gridContainer}
    >
      <Grid style={Styles.imgContainer} item md={2} xs={5} xl={3}>
        <Box
          sx={{ display: { xs: "flex", md: "none" } }}
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: -20,
          }}
        >
          <MenuIcon style={{ color: "white" }} onClick={handleDrawerToggle} />
        </Box>
        <img
          onClick={() => navigation("/")}
          alt="basimart"
          width={200}
          height={90}
          style={{ margin: -20, marginLeft: 15, cursor: "pointer" }}
          src={require("../assets/logo1.png")}
        />
      </Grid>

      <Grid
        style={{ display: "flex", flexDirection: "row-reverse" }}
        md={10}
        xs={6}
        item
      >
        <div onClick={logout} style={Styles.webLoginText}>
          Salir
        </div>
      </Grid>
    </Grid>
  );
}

const Styles = {
  gridContainer: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#003D28 ",
    top: 0,
    position: "fixed",
    zIndex: 100,
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
  webLoginText: {
    display: "flex",
    backgroundColor: "#2D634C",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    cursor: "pointer",
    color: "white",
  },
};
