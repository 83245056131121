import React, { useContext, useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Grid } from "@mui/material";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import BookmarkAddedRoundedIcon from "@mui/icons-material/BookmarkAddedRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import axios from "../axios";
import { commafy } from "../components/commafy";

const VendorWeekleyBonus = ({ vendorAuth, orders }) => {
  const [bonus, setBonus] = useState(0);
  const [challenge, setChallenge] = useState(0);
  const [ordersPercent, setPercent] = useState(0);
  const [progress, setProgress] = useState(0);

  const getWeeklyBonus = async () => {
    axios
      .get(`/api/v1/vendor/weeklyBonus/${vendorAuth?.vendor?._id}`)
      .then((res) => {
        setBonus(res?.data?.vendorWeeklyBonus?.bonus);
        setChallenge(res?.data?.vendorWeeklyBonus?.challenge);
        setProgress(res?.data?.vendorProgress);
        progress > 0 ? setPercent((progress * 100) / challenge) : setPercent(0);
      });
  };

  useEffect(() => {
    getWeeklyBonus();
  });

  const Styles = {
    tittle: {
      color: "#4B566B",
      fontSize: 15,
      fontWeight: "bold",
      paddingLeft: "10px",
      marginTop: "10px",
      display: "flex",
      textAlign: "center",
    },
    container: { marginTop: "15px", marginLeft: "10px", marginRight: "10px" },
    icon: { fontSize: "19px" },
    subtitle: { fontSize: "12.5px" },
    subtitleRigth: { fontSize: "12.5px", textAlign: "rigth" },
  };

  return (
    <>
      <h2 style={Styles.tittle}> Bonus Semanal </h2>
      <ProgressBar
        completed={Math.min(ordersPercent, 100)}
        maxCompleted={100}
        bgColor={"green"}
        borderRadius={20}
        margin={10}
        width={"90%"}
        height={"22px"}
        labelSize="12px"
        labelAlignment={"center"}
      />

      <Grid container xs={12} md={12} style={Styles.container}>
        <Grid item xs={6} md={6}>
          <p style={Styles.subtitle}>
            <EmojiEventsRoundedIcon style={Styles.icon} />
            <strong>Bonus:</strong> ₡{commafy(bonus)}
          </p>
        </Grid>
        <Grid item xs={6} md={6}>
          <p style={Styles.subtitleRigth}>
            <FlagRoundedIcon style={Styles.icon} />
            <strong>Meta: </strong> {challenge} ventas
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default VendorWeekleyBonus;
