import React, { useState } from "react";
import { Grid } from "@mui/material";
import { commafy } from "../components/commafy";

const VendorMoneyStatus = ({ orders, vendor }) => {
  console.log({ orders, vendor });
  const [isMobile, setIsMobile] = useState(getMobile());
  function getMobile() {
    const body = document.querySelector("body");
    const width = parseFloat(window.getComputedStyle(body).width);
    if (width > 900) {
      return false;
    } else {
      return true;
    }
  }

  window.addEventListener("resize", () => {
    setIsMobile(getMobile());
  });
  return (
    <Grid container xs={12} md={12} lg={12}>
      {orders && vendor && (
        <>
          {!isMobile && (
            <div style={{ marginTop: "90px", width: "100%" }}>
              <Grid container md={12} xs={12}>
                <Grid md={4} xs={12}>
                  <div style={Styles.total}>
                    <div
                      style={{
                        color: "#f7f9fc",
                        fontSize: 25,
                        fontWeight: "bold",
                      }}
                    >
                      ₡
                      {commafy(
                        orders?.commission?.[0]?.sum_val?.toFixed(2) || 0
                      )}
                    </div>

                    <div
                      style={{
                        color: "white",
                        fontSize: 11,
                      }}
                    >
                      COMISION DEL MES
                    </div>
                  </div>
                </Grid>
                <Grid md={4} xs={12}>
                  <div style={Styles.total}>
                    <div
                      style={{
                        color: "#f7f9fc",
                        fontSize: 25,
                        fontWeight: "bold",
                      }}
                    >
                      ₡
                      {commafy(
                        orders?.today[0]?.sum_val
                          ? orders?.today[0]?.sum_val?.toFixed(1)
                          : 0
                      )}
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontSize: 11,
                      }}
                    >
                      VENTAS DE HOY{" "}
                    </div>
                  </div>
                </Grid>
                <Grid md={4} xs={12}>
                  <div style={Styles.total}>
                    <div
                      style={{
                        color: "#f7f9fc",
                        fontSize: 25,
                        fontWeight: "bold",
                      }}
                    >
                      ₡
                      {commafy(
                        orders?.thisMonth[0]?.sum_val
                          ? orders?.thisMonth[0]?.sum_val?.toFixed(1)
                          : 0
                      )}
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontSize: 11,
                      }}
                    >
                      VENTAS DEL MES
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}

          {isMobile && (
            <>
              <Grid container xs={12} md={12}>
                <Grid xs={12} md={12}>
                  <div style={Styles.total}>
                    <div
                      style={{
                        color: "#f7f9fc",
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      ₡
                      {commafy(
                        vendor?.commission
                          ? orders?.commission[0]?.sum_val *
                            (vendor?.commission / 100)
                            ? (
                                orders?.commission[0]?.sum_val *
                                (vendor?.commission / 100)
                              ).toFixed(1)
                            : 0
                          : 0
                      )}
                    </div>

                    <div
                      style={{
                        color: "white",
                        fontSize: 10,
                      }}
                    >
                      COMISION
                    </div>
                  </div>
                </Grid>

                <Grid xs={12} md={12}>
                  <div style={Styles.total}>
                    <div
                      style={{
                        color: "#f7f9fc",
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      ₡
                      {commafy(
                        orders?.today[0]?.sum_val
                          ? orders?.today[0]?.sum_val?.toFixed(1)
                          : 0
                      )}
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontSize: 10,
                      }}
                    >
                      VENTAS DE HOY{" "}
                    </div>
                  </div>
                </Grid>

                <Grid xs={12} md={12}>
                  <div style={Styles.total}>
                    <div
                      style={{
                        color: "#f7f9fc",
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      ₡
                      {commafy(
                        orders?.thisMonth[0]?.sum_val
                          ? orders?.thisMonth[0]?.sum_val?.toFixed(1)
                          : 0
                      )}
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontSize: 10,
                      }}
                    >
                      VENTAS DEL MES
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default VendorMoneyStatus;

const Styles = {
  total: {
    backgroundColor: "#003D28 ",
    background:
      "linear-gradient(184deg, rgba(4,61,40,1) 35%, rgba(30,86,67,0.9672911400888481) 54%, rgba(4,61,40,1) 100%",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    flex: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "60%",
  },
};
