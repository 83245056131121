import { useCallback, useEffect, useState } from "react";

const useIsMobile = () => {
  const getMobile = useCallback(() => {
    const body = document.querySelector("body");
    if (!body) return false;
    const width = parseFloat(window.getComputedStyle(body).width);
    if (width > 900) {
      return false;
    } else {
      return true;
    }
  }, []);

  const [isMobile, setIsMobile] = useState(getMobile());

  useEffect(() => {
    const handler = () => {
      setIsMobile(getMobile());
    };
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, [getMobile]);

  return isMobile;
};

export default useIsMobile;
