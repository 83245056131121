import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Grid } from "@mui/material";
import Rating from "@mui/material/Rating";

const VendorRating = ({ vendor }) => {
  const nextLevel = () => {
    if (vendor?.level === "Novato") return 5;
    if (vendor?.level === "Básico") return 50;
    if (vendor?.level === "Intermedio") return 250;
    if (vendor?.level === "Avanzado") return 500;
    if (vendor?.level === "Basi Star") return 500;
  };

  const completed = () => {
    const percent = (vendor?.totalOrders * 100) / nextLevel();
    return percent;
  };

  const Styles = {
    tittle: {
      color: "#4B566B",
      fontSize: 15,
      fontWeight: "bold",
      paddingLeft: "5px",
      marginTop: "10px",
      display: "flex",
      textAlign: "left",
      justifyContent: "left",
    },
    image: {
      width: "50px",
      height: "50px",
      textAlign: "right",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      flexDirection: "row",
      alignContent: "center",
      display: "block",
      margin: "auto",
      marginTop: "5px",
    },
  };

  return (
    <>
      <Grid
        container
        xs={10}
        md={12}
        style={{ marginTop: "5px", marginLeft: "10px", marginRight: "10px" }}
      >
        <Grid item xs={2.5} md={2.5}>
          <img src={vendor?.image} style={Styles.image} alt="alt"></img>
        </Grid>

        <Grid xs={9} md={9}>
          <h2 style={Styles.tittle}>{vendor?.storeName}</h2>
          <div></div>
          <Rating
            style={{ fontSize: "15px", paddingLeft: "5px", marginTop: "3px" }}
            name="read-only"
            value={Number(vendor?.rating)}
            precision={0.5}
            readOnly
          />
        </Grid>

        <Grid item xs={9} md={9}>
          <ProgressBar
            completed={completed()}
            bgColor={"green"}
            labelColor={"green"}
            borderRadius={5}
            margin={10}
            width={"90%"}
            height={"13px"}
            labelAlignment={"center"}
          />
        </Grid>

        <Grid xs={3} md={3}>
          <p style={{ fontSize: "13px", marginTop: "6px" }}>
            {vendor?.totalOrders}/{nextLevel()} <strong>{vendor?.level}</strong>
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default VendorRating;
