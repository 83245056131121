import PropTypes from "prop-types";
import axios from "../../axios";
import AuthContext from "../../components/context";
import Box from "@mui/material/Box";

import Drawer from "@mui/material/Drawer";

import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import VendorNavbarComponent from "../../Vendor/VendorNavbarComponent";
import VendorDashBoard from "../../Vendor/VendorDashBoard";
import VendorMoneyStatus from "../../Vendor/VendorMoneyStatus";
import VendorOrderStatus from "../../Vendor/VendorOrderStatus";
import VendorWeekleyBonus from "../../Vendor/VendorWeekleyBonus";
import VendorRating from "../../Vendor/VendorRating";
import VendorTable from "../../Vendor/VendorTable";
import VendorTasks from "../../Vendor/VendorTasks";

import CircularProgress from "@mui/material/CircularProgress";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import PriceCheckSharpIcon from "@mui/icons-material/PriceCheckSharp";
import ShoppingCartCheckoutSharpIcon from "@mui/icons-material/ShoppingCartCheckoutSharp";
import DeliveryDiningSharpIcon from "@mui/icons-material/DeliveryDiningSharp";

import { isMobile } from "react-device-detect";
import io from "socket.io-client";
const drawerWidth = 250;

function VendorScreen(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeTab, setActiveTabs] = useState("Tablero de tu Tienda");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [loading, setLoading] = useState();

  const URL =
    "https://charts.mongodb.com/charts-ecommerce-yonnp/embed/charts?id=635ac0a9-e19d-49ce-839b-de7e40cf4974&maxDataAge=3600&theme=light&autoRefresh=true&attribution=false";

  const [orders, setOrders] = useState();
  const vendorAuth = useContext(AuthContext);
  const [vendor, setVendor] = useState();
  const [order, setOrder] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const req = await axios.post(
        `/api/v1/vendor/order/${vendorAuth.vendor._id}`
      );
      setOrder(req?.data?.order);
      setLoading(false);
      axios.get(`/api/v1/vendor/${vendorAuth.vendor._id}`).then((res) => {
        setVendor(res.data.vendorId);
      });
    }

    const socket = io(
      "https://urchin-app-49cxg.ondigitalocean.app/api/socket",
      {
        transports: ["websocket"],
      }
    ).connect();
    socket.on("newThought", (thought) => {
      return fetchData();
    });
    fetchData();
  }, [vendorAuth]);

  const getOrderAmount = async () => {
    axios
      .get(`/api/v1/order/vendorOrderAmount/${vendorAuth?.vendor?._id}`)
      .then((res) => {
        axios
          .post(`/api/v1/vendor/checkStoreName`, {
            storeName: vendorAuth?.vendor?.storeName,
          })
          .then((res) => {
            setVendor(res.data.store);
          });
        setOrders(res.data);
      });
  };

  useEffect(() => {
    getOrderAmount();
  }, [vendorAuth]);

  const drawer = (
    <Grid
      md={2}
      lg={2}
      sm={2}
      sx={12}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <VendorDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", background: "#F6F9FC", width: "100%" }}>
      <VendorNavbarComponent handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              minHeight: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid item md={9} lg={10} sm={12} xs={12}>
            <Grid>
              <div className="px-6 grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-2">
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#000",
                      padding: "10px",
                      marginTop: 90,
                      display: "flex",
                    }}
                  >
                    Resumen
                  </h2>
                </div>
                <div className="col-span-12 md:col-span-10">
                  <VendorMoneyStatus orders={orders} vendor={vendor} />
                </div>
              </div>

              <div className="mt-4 px-6 grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-3">
                  {orders?.deliveredOrders && (
                    <VendorOrderStatus
                      icon={<TrendingUpOutlinedIcon />}
                      text="Total"
                      balance={order?.length}
                      mainContainer={orderStyles.orderMainContainer}
                      childContainer={orderStyles.container}
                      iconStyle={orderStyles.iconStyle}
                      textStyle={orderStyles.orderTextStyle}
                      balanceStyle={orderStyles.orderQuantity}
                    />
                  )}
                </div>
                <div className="col-span-12 md:col-span-3">
                  {orders?.pendingOrders && (
                    <VendorOrderStatus
                      icon={<ShoppingCartCheckoutSharpIcon />}
                      text="Confirmando"
                      balance={orders?.pendingOrders.length}
                      mainContainer={orderStyles.orderMainContainer}
                      childContainer={orderStyles.container}
                      iconStyle={orderStyles.iconStyle}
                      textStyle={orderStyles.orderTextStyle}
                      balanceStyle={orderStyles.orderQuantity}
                    />
                  )}
                </div>
                <div className="col-span-12 md:col-span-3">
                  {orders?.confirmedOrders && (
                    <VendorOrderStatus
                      icon={<PriceCheckSharpIcon />}
                      text="Confirmados"
                      balance={orders.confirmedOrders.length}
                      mainContainer={orderStyles.orderMainContainer}
                      childContainer={orderStyles.container}
                      iconStyle={orderStyles.iconStyle}
                      textStyle={orderStyles.orderTextStyle}
                      balanceStyle={orderStyles.orderQuantity}
                    />
                  )}
                </div>
                <div className="col-span-12 md:col-span-3">
                  {orders?.deliveredOrders && (
                    <VendorOrderStatus
                      icon={<DeliveryDiningSharpIcon />}
                      text="Entregados"
                      balance={orders.deliveredOrders.length}
                      mainContainer={orderStyles.orderMainContainer}
                      childContainer={orderStyles.container}
                      iconStyle={orderStyles.iconStyle}
                      textStyle={orderStyles.orderTextStyle}
                      balanceStyle={orderStyles.orderQuantity}
                    />
                  )}
                </div>
              </div>

              <div className="mt-4 px-6 grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-6">
                  <div style={orderStyles.container}>
                    <div style={orderStyles.containerBonus}>
                      {orders?.weekOrders && (
                        <VendorWeekleyBonus
                          vendorAuth={vendorAuth}
                          orders={orders.weekOrders}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div style={orderStyles.container}>
                    <div style={orderStyles.containerBonus}>
                      <VendorRating vendor={vendor} />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="mt-4 px-6 grid grid-cols-12 gap-4"
                container
                xs={12}
                md={12}
                columnSpacing={{ xs: 2, md: 2 }}
                justifyContent="center"
              >
                <div className="col-span-12 md:col-span-8">
                  <div style={orderStyles.container}>
                    <div
                      style={
                        !isMobile
                          ? orderStyles.containerTable
                          : orderStyles.containerTableMobile
                      }
                    >
                      {order && <VendorTable order={order} vendor={vendor} />}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <div style={orderStyles.container}>
                    <div
                      style={orderStyles.containerTasks}
                      className="!overflow-y-scroll"
                    >
                      <VendorTasks order={order} />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ height: "20px" }}> </div>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

VendorScreen.propTypes = {
  window: PropTypes.func,
};

export default VendorScreen;

const orderStyles = {
  orderMainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  iconStyle: {
    color: "white",
    fontSize: 10,
    backgroundColor: "#003D28 ",
    background:
      "linear-gradient(121deg, rgba(4,61,40,1) 34%, rgba(30,86,67,0.9672911400888481) 55%, rgba(4,61,40,1) 100%",
    display: "flex",
    alignSelf: "center",
    borderRadius: 10,
    padding: 20,
    marginLeft: 15,
  },
  orderTextStyle: {
    color: "black",
    fontSize: 11,
    paddingLeft: 15,
    marginLeft: 5,
  },
  orderQuantity: {
    color: "#4B566B",
    fontSize: 26,
    fontWeight: "bold",
    paddingLeft: 15,
    marginLeft: 5,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
    marginRight: 15,
    width: "25%",
    flex: " 0 0 25%",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  container: {
    background: "white",
    width: "100%",
    borderRadius: 8,
    borderWidth: 2,
    borderColor: "#f2f2f4",
    padding: "5px",
    // margin: "5px",
  },
  containerTable: {
    overflow: "scroll",
    height: "250px",
    overflowX: "hidden",
    display: "grid",
    justifyItems: "center",
  },

  containerTableMobile: {
    height: "235px",
    overflowX: "scroll",
    overflowY: "hidden",
    display: "grid",
    justifyItems: "center",
  },
  containerTasks: {
    height: "250px",
    overflowY: "hidden",
  },
  containerBonus: {
    height: "100px",
  },
  containerTotal: {
    height: "305px",
    overflowY: "hidden",
  },

  containerTotalMobile: {
    height: "70px",
    overflowY: "hidden",
  },
  containerChart: {
    height: "305px",
    overflowY: "hidden",
  },
};
