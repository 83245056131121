import React, { useState } from "react";
import { Grid } from "@mui/material";

function VendorOrderStatus({
  text,
  balance,
  icon,
  mainContainer,
  childContainer,
  iconStyle,
  textStyle,
  balanceStyle,
}) {
  const [isMobile, setIsMobile] = useState(getMobile());

  function getMobile() {
    const body = document.querySelector("body");
    const width = parseFloat(window.getComputedStyle(body).width);
    if (width > 900) {
      return false;
    } else {
      return true;
    }
  }

  window.addEventListener("resize", () => {
    setIsMobile(getMobile());
  });

  return (
    <Grid container xs={12} md={12} lg={12} style={mainContainer}>
      <Grid xs={12} md={12} item style={childContainer}>
        {!isMobile && (
          <div style={Styles.row}>
            <div style={Styles.col}>
              <div style={balanceStyle}>{balance}</div>
              <div style={textStyle}>{text}</div>
            </div>
            <div style={Styles.col}>
              <div style={iconStyle}>{icon}</div>
            </div>
          </div>
        )}

        {isMobile && (
          <>
            <Grid container xs={11} md={12} lg={12} style={mainContainer}>
              <Grid xs={7} md={12}>
                <div style={Styles.orderQuantitMobile}>{balance}</div>
                <div style={Styles.orderTextMobile}>{text}</div>
              </Grid>
              <Grid xs={4} md={12}>
                <div style={Styles.iconMobile}>{icon}</div>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default VendorOrderStatus;

const Styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
  },
  iconMobile: {
    color: "white",
    fontSize: 10,
    backgroundColor: "#003D28 ",
    background:
      "linear-gradient(linear-gradient(121deg, rgba(4,61,40,1) 34%, rgba(30,86,67,0.9672911400888481) 55%, rgba(4,61,40,1) 100%",
    borderRadius: 5,
    padding: 10,
    marginLeft: 5,
    marginTop: 6,
  },
  orderTextMobile: {
    color: "black",
    fontSize: 12,
    paddingLeft: 3,
    marginLeft: 3,
  },
  orderQuantitMobile: {
    color: "#4B566B",
    fontSize: 26,
    fontWeight: "bold",
    paddingLeft: 3,
    marginLeft: 3,
  },
};
